'use client'
import React, { useState } from 'react';
import './styles.css';

const Showcase = ({ color, textcolor, active, activetext }) => {
  const [activeTab, setActiveTab] = useState('frontend');

  return (
    <section className={`${color} text-${textcolor} py-5`}>
      <div className="text-center ">
        <h2 className="sec-subtile-6">Technologies Tech</h2>
      </div>
      <h3 className="text-center py-3"> Technologies We work With</h3>
      <p className="text-xl text-center pb-11">
        JBcodeapp works with cutting-edge technologies, including Flutter, React Native, Python, AI, and <br/>
        more, to deliver high-performance applications and solutions that drive business success.
      </p>
      <div className="container">
        <div className="row">
          {/* tab menu */}
          {/* <div className="flex flex-wrap justify-center space-x-2 py-2">
            <button
              className={`px-4 py-2 border border-gray-500 rounded-md mb-2 sm:mb-0 ${activeTab === 'frontend' ? `${active} ${activetext}` : 'hover:bg-white hover:text-black'}`}
              onClick={() => setActiveTab('frontend')}
            >
              Frontend
            </button>
            <button
              className={`px-4 py-2 border border-gray-500 rounded-md mb-2 sm:mb-0 ${activeTab === 'backend' ? `${active} ${activetext}` : 'hover:bg-white hover:text-black'}`}
              onClick={() => setActiveTab('backend')}
            >
              Backend
            </button>
            <button
              className={`px-4 py-2 border border-gray-500 rounded-md mb-2 sm:mb-0 ${activeTab === 'mobile' ? `${active} ${activetext}` : 'hover:bg-white hover:text-black'}`}
              onClick={() => setActiveTab('mobile')}
            >
              Mobile
            </button>
            <button
              className={`px-4 py-2 border border-gray-500 rounded-md mb-2 sm:mb-0 ${activeTab === 'database' ? `${active} ${activetext}` : 'hover:bg-white hover:text-black'}`}
              onClick={() => setActiveTab('database')}
            >
              Database
            </button>
            <button
              className={`px-4 py-2 border border-gray-500 rounded-md mb-2 sm:mb-0 ${activeTab === 'cmsecommerce' ? `${active} ${activetext}` : 'hover:bg-white hover:text-black'}`}
              onClick={() => setActiveTab('cmsecommerce')}
            >
              CMS/E-commerce
            </button>

          </div> */}
          <div className="py-2 overflow-x-auto">
            <div className="flex flex-wrap justify-center space-x-2 min-w-max">
              <button
                className={`px-4 py-2 border border-gray-500 rounded-md mb-2 sm:mb-0 ${activeTab === 'frontend' ? `${active} ${activetext}` : 'hover:bg-white hover:text-black'}`}
                onClick={() => setActiveTab('frontend')}
              >
                Frontend
              </button>
              <button
                className={`px-4 py-2 border border-gray-500 rounded-md mb-2 sm:mb-0 ${activeTab === 'backend' ? `${active} ${activetext}` : 'hover:bg-white hover:text-black'}`}
                onClick={() => setActiveTab('backend')}
              >
                Backend
              </button>
              <button
                className={`px-4 py-2 border border-gray-500 rounded-md mb-2 sm:mb-0 ${activeTab === 'mobile' ? `${active} ${activetext}` : 'hover:bg-white hover:text-black'}`}
                onClick={() => setActiveTab('mobile')}
              >
                Mobile
              </button>
              <button
                className={`px-4 py-2 border border-gray-500 rounded-md mb-2 sm:mb-0 ${activeTab === 'database' ? `${active} ${activetext}` : 'hover:bg-white hover:text-black'}`}
                onClick={() => setActiveTab('database')}
              >
                Database
              </button>
              <button
                className={`px-4 py-2 border border-gray-500 rounded-md mb-2 sm:mb-0 ${activeTab === 'cmsecommerce' ? `${active} ${activetext}` : 'hover:bg-white hover:text-black'}`}
                onClick={() => setActiveTab('cmsecommerce')}
              >
                CMS/E-commerce
              </button>
            </div>
          </div>

          {/* icons */}
          <div className="col-xxl-12">
            <div className="brand__list showcase-mixitup mt-5">

              {/* Frontend */}
              {activeTab === 'frontend' && (
                <>
                  <a className="showcase__item-4 mix calender " href="/services/web/reactjs-development-services">
                    <div className="brand__item lg:border-l border-[#e9e9e9]">
                      <i className="fa-brands fa-react fa-2x" style={{ fontSize: '3.5em', color: '#61DAFB', padding: '8px' }} />
                      <h5 className={`text-center text-${textcolor}`}>ReactJS</h5>
                    </div>
                  </a>

                  {/* <a className="showcase__item-4 mix calender" href="/services/web/js-development-service"> */}
                  <div className="brand__item">
                    <i className="fa-brands fa-js fa-2x" style={{ fontSize: '3.5em', color: '#F7DF1E', padding: '8px' }} />
                    <h5 className={`text-center text-${textcolor}`}>JavaScript</h5>
                  </div>
                  {/* </a> */}
                  <a className="showcase__item-4 mix calender" href="/services/web/typescript-development-services">
                    <div className="brand__item flex flex-col items-center justify-center p-4 h-full">
                      <img src="/assets/images/icon/jbcodeapp_tech_typescript.svg" alt="TypeScript" className="w-14 h-14 " />
                      <h5 className={`text-center pt-3 text-${textcolor}`}>TypeScript</h5>
                    </div>
                  </a>
                  <a className="showcase__item-4 mix calender" href="/services/web/nextjs-development">
                    <div className="brand__item flex flex-col items-center justify-center p-4 h-full">
                      <img src="/assets/images/icon/jbcodeapp_tech_nextjs.svg" alt="NextJS" className="w-14 h-14 !bg-white !rounded-full" />
                      <h5 className={`text-center pt-3 text-${textcolor}`}>NextJS</h5>
                    </div>
                  </a>
                  {/* <a className="showcase__item-4 mix calender" href="/services/web/vuejs-development-service"> */}
                  <div className="brand__item flex flex-col items-center justify-center p-4 h-full">
                    <img src="/assets/images/icon/jbcodeapp_tech_tailwind-css.svg" alt="Tailwind CSS" className="w-14 h-14" />
                    <h5 className={`text-center pt-3 text-${textcolor}`}>Tailwind CSS</h5>
                  </div>
                  {/* </a> */}
                  {/* <a className="showcase__item-4 mix calender" href="/services/web/css-development-service"> */}
                  <div className="brand__item">
                    <i className="fa-brands fa-angular fa-2x" style={{ fontSize: '3.5em', color: '#DD0031', padding: '8px' }} />
                    <h5 className={`text-center text-${textcolor}`}>Angular</h5>
                  </div>
                  {/* </a> */}


                  {/* 2nd Line */}
                  <a className="showcase__item-4 mix calender" href="/services/web/vuejs-development">
                    <div className="brand__item  lg:border-l border-[#e9e9e9]">
                      <i className="fa-brands fa-vuejs fa-2x" style={{ fontSize: '3.5em', color: '#42B883', padding: '8px' }} />
                      <h5 className={`text-center text-${textcolor}`}>VueJS</h5>
                    </div>
                  </a>
                  {/* <a className="showcase__item-4 mix calender" href="/services/web/vuejs-development-service"> */}
                  <div className="brand__item flex flex-col items-center justify-center p-4 h-full">
                    <img src="/assets/images/icon/jbcodeapp_tech_jquery.svg" alt="JQuery" className="w-14 h-14" />
                    <h5 className={`text-center pt-3 text-${textcolor}`}>JQuery</h5>
                  </div>
                  {/* </a> */}
                  <a className="showcase__item-4 mix calender" href="/services/web/html5-web-development">
                    <div className="brand__item">
                      <i className="fa-brands fa-html5 fa-2x" style={{ fontSize: '3.5em', color: '#E34F26', padding: '8px' }} />
                      <h5 className={`text-center text-${textcolor}`}>HTML</h5>
                    </div>
                  </a>
                  {/* <a className="showcase__item-4 mix calender" href="/services/web/vuejs-development-service"> */}
                  <div className="brand__item flex flex-col items-center justify-center p-4 h-full">
                    <img src="/assets/images/icon/jbcodeapp_tech_vitejs.svg" alt="Vite" className="w-14 h-14" />
                    <h5 className={`text-cente pt-3r text-${textcolor}`}>Vite</h5>
                  </div>
                  {/* </a> */}
                  {/* <a className="showcase__item-4 mix calender" href="/services/web/bootstrap-development-service"> */}
                  <div className="brand__item">
                    <i className="fa-brands fa-bootstrap fa-2x" style={{ fontSize: '3.5em', color: '#563D7C', padding: '8px' }} />
                    <h5 className={`text-center text-${textcolor}`}>Bootstrap</h5>
                  </div>
                  {/* </a> */}
                  {/* <a className="showcase__item-4 mix calender" href="/services/web/vuejs-development-service"> */}
                  <div className="brand__item flex flex-col items-center justify-center p-4 h-full">
                    <img src="/assets/images/icon/jbcodeapp_tech_nuxt.svg" alt="NuxtJS" className="w-14 h-14" />
                    <h5 className={`text-center pt-3 text-${textcolor}`}>NuxtJS</h5>
                  </div>
                  {/* </a> */}
                </>
              )}

              {/* Backend */}
              {activeTab === 'backend' && (
                <>
                  <a className="showcase__item-4 mix game " href="/services/web/php-development-services">
                    <div className="brand__item lg:border-l border-[#e9e9e9]">
                      <i className="fa-brands fa-php fa-2x" style={{ fontSize: '3.5em', color: '#777BB4', padding: '8px' }} />
                      <h5 className={`text-center text-${textcolor}`}>PHP</h5>
                    </div>
                  </a>
                  <a className="showcase__item-4 mix game" href="/services/web/laravel-application-development">
                    <div className="brand__item">
                      <i className="fa-brands fa-laravel fa-2x" style={{ fontSize: '3.5em', color: '#FF2D20', padding: '8px' }} />
                      <h5 className={`text-center text-${textcolor}`}>Laravel</h5>
                    </div>
                  </a>
                  <a className="showcase__item-4 mix game" href="/services/web/nodejs-development-company">
                    <div className="brand__item">
                      <i className="fa-brands fa-node-js fa-2x" style={{ fontSize: '3.5em', color: '#339933', padding: '8px' }} />
                      <h5 className={`text-center text-${textcolor}`}>NodeJS</h5>
                    </div>
                  </a>
                  {/* <a className="showcase__item-4 mix game" href="/services/web/node-development-service"> */}
                  <div className="brand__item flex flex-col items-center justify-center p-4 h-full">
                    <img src="/assets/images/icon/jbcodeapp_tech_express.svg" alt="ExpressJS" className="w-14 h-14 " />
                    <h5 className={`text-center pt-3 text-${textcolor}`}>ExpressJS</h5>
                  </div>
                  {/* </a> */}
                  <a className="showcase__item-4 mix calender" href="/services/web/typescript-development-services">
                    <div className="brand__item flex flex-col items-center justify-center p-4 h-full">
                      <img src="/assets/images/icon/jbcodeapp_tech_typescript.svg" alt="TypeScript" className="w-14 h-14 " />
                      <h5 className={`text-center pt-3 text-${textcolor}`}>TypeScript</h5>
                    </div>
                  </a>
                  <a className="showcase__item-4 mix calender" href="/services/web/python-web-development">
                    <div className="brand__item flex flex-col items-center justify-center p-4 h-full">
                      <img src="/assets/images/icon/jbcodeapp_tech_python.svg" alt="TypeScript" className="w-14 h-14 " />
                      <h5 className={`text-center pt-3 text-${textcolor}`}>Python</h5>
                    </div>
                  </a>

                </>
              )}

              {/* Database */}
              {activeTab === 'database' && (
                <>
                  <a className="showcase__item-4 mix logos" href="/services/web/mysql-development-company">
                    <div className="brand__item  lg:border-l border-[#e9e9e9]">
                      <img src="/assets/images/what-we-do/mysql.webp" alt="Mysql" style={{ fontSize: '3.5em', color: '#7AB55C', padding: '8px' }} />
                      <h5 className={`text-center text-${textcolor}`}>Mysql</h5>
                    </div>
                  </a>
                  <a className="showcase__item-4 mix calender" href="/services/web/mongodb-development-services">
                    <div className="brand__item flex flex-col items-center justify-center p-4 h-full">
                      <img src="/assets/images/icon/jbcodeapp_tech_mongodb.svg" alt="Mongodb" className="w-14 h-14 " />
                      <h5 className={`text-center pt-3 text-${textcolor}`}>Mongodb</h5>
                    </div>
                  </a>
                  {/* <a className="showcase__item-4 mix calender" href="/services/web/vuejs-development-service"> */}
                  <div className="brand__item flex flex-col items-center justify-center p-4 h-full">
                    <img src="/assets/images/icon/jbcodeapp_tech_postgresql.svg" alt="PostgresSQL" className="w-14 h-14 " />
                    <h5 className={`text-center pt-3 text-${textcolor}`}>PostgresSQL</h5>
                  </div>
                  {/* </a> */}
                  {/* <a className="showcase__item-4 mix calender" href="/services/web/vuejs-development-service"> */}
                  <div className="brand__item flex flex-col items-center justify-center p-4 h-full">
                    <img src="/assets/images/icon/jbcodeapp_tech_firebase.svg" alt="Firebase" className="w-14 h-14 " />
                    <h5 className={`text-center pt-3 text-${textcolor}`}>Firebase</h5>
                  </div>
                  {/* </a> */}
                  {/* <a className="showcase__item-4 mix calender" href="/services/web/vuejs-development-service"> */}
                  <div className="brand__item flex flex-col items-center justify-center p-4 h-full">
                    <img src="/assets/images/icon/jbcodeapp_tech_oracle.svg" alt="Oracle" className="w-14 h-14 " />
                    <h5 className={`text-center pt-3 text-${textcolor}`}>Oracle</h5>
                  </div>
                  {/* </a> */}
                  {/* <a className="showcase__item-4 mix calender" href="/services/web/vuejs-development-service"> */}
                  <div className="brand__item flex flex-col items-center justify-center p-4 h-full">
                    <img src="/assets/images/icon/jbcodeapp_tech_mssql.svg" alt="MS SQL" className="w-14 h-14 " />
                    <h5 className={`text-center pt-3 text-${textcolor}`}>MS SQL</h5>
                  </div>
                  {/* </a> */}

                </>
              )}

              {/* CMS/E-commerce */}
              {activeTab === 'cmsecommerce' && (
                <>
                  <a className="showcase__item-4 mix flyer" href="/services/web/wordpress-website-development">
                    <div className="brand__item  lg:border-l border-[#e9e9e9]">
                      <i className="fa-brands fa-wordpress fa-2x" style={{ fontSize: '3.5em', color: '#21759B', padding: '8px' }} />
                      <h5 className={`text-center text-${textcolor}`}>Wordpress</h5>
                    </div>
                  </a>
                  {/* <a className="showcase__item-4 mix flyer" href="/services/web/shopify-development-service"> */}
                  <div className="brand__item">
                    <i className="fa-brands fa-shopify fa-2x" style={{ fontSize: '3.5em', color: '#7AB55C', padding: '8px' }} />
                    <h5 className={`text-center text-${textcolor}`}>Shopify</h5>
                  </div>
                  {/* </a> */}
                  {/* <a className="showcase__item-4 mix calender" href="/services/web/vuejs-development-service"> */}
                  <div className="brand__item flex flex-col items-center justify-center p-4 h-full">
                    <img src="/assets/images/icon/jbcodeapp_tech_drupal.svg" alt="Drupal" className="w-14 h-14 " />
                    <h5 className={`text-center pt-3 text-${textcolor}`}>Drupal</h5>
                  </div>
                  {/* </a> */}
                  {/* <a className="showcase__item-4 mix calender" href="/services/web/vuejs-development-service"> */}
                  <div className="brand__item flex flex-col items-center justify-center p-4 h-full">
                    <img src="/assets/images/icon/jbcodeapp_tech_strapi.svg" alt="Strapi" className="w-14 h-14 " />
                    <h5 className={`text-center pt-3 text-${textcolor}`}>Strapi</h5>
                  </div>
                  {/* </a> */}
                  {/* <a className="showcase__item-4 mix calender" href="/services/web/vuejs-development-service"> */}
                  <div className="brand__item flex flex-col items-center justify-center p-4 h-full">
                    <img src="/assets/images/icon/jbcodeapp_tech_magento.svg" alt="Magento" className="w-14 h-14 " />
                    <h5 className={`text-center pt-3 text-${textcolor}`}>Magento</h5>
                  </div>
                  {/* </a> */}
                  <a className="showcase__item-4 mix calender" href="/services/web/woocommerce-development">
                    <div className="brand__item flex flex-col items-center justify-center p-4 h-full">
                      <img src="/assets/images/icon/jbcodeapp_tech_woocommerce.svg" alt="Woocommerce" className="w-14 h-14 " />
                      <h5 className={`text-center pt-3 text-${textcolor}`}>Woocommerce</h5>
                    </div>
                  </a>
                </>
              )}

              {/* Mobile */}
              {activeTab === 'mobile' && (
                <>
                  <a className="showcase__item-4 mix brochure" href="/services/mobile/ios-app-development">
                    <div className="brand__item  lg:border-l border-[#e9e9e9]">
                      <i className="fa-brands fa-apple fa-2x" style={{ fontSize: '3.5em', color: '#6D6E71', padding: '8px' }} />
                      <h5 className={`text-center text-${textcolor}`}>iOS</h5>
                    </div>
                  </a>
                  {/* <a className="showcase__item-4 mix calender" href="/services/web/vuejs-development-service"> */}
                  <div className="brand__item flex flex-col items-center justify-center p-4 h-full">
                    <img src="/assets/images/icon/jbcodeapp_tech_swift.svg" alt="Swift" className="w-14 h-14 " />
                    <h5 className={`text-center pt-3 text-${textcolor}`}>Swift</h5>
                  </div>
                  {/* </a> */}
                  <a className="showcase__item-4 mix brochure" href="/services/mobile/android-application-development">
                    <div className="brand__item">
                      <i className="fa-brands fa-android fa-2x" style={{ fontSize: '3.5em', color: '#3DDC84', padding: '8px' }} />
                      <h5 className={`text-center text-${textcolor}`}>Android</h5>
                    </div>
                  </a>
                  {/* <a className="showcase__item-4 mix calender" href="/services/web/vuejs-development-service"> */}
                  <div className="brand__item flex flex-col items-center justify-center p-4 h-full">
                    <img src="/assets/images/icon/jbcodeapp_tech_kotlin.svg" alt="Kotlin" className="w-14 h-14 " />
                    <h5 className={`text-center pt-3 text-${textcolor}`}>Kotlin</h5>
                  </div>
                  {/* </a> */}
                  <a className="showcase__item-4 mix calender" href="/services/mobile/flutter-app-development">
                    <div className="brand__item flex flex-col items-center justify-center p-4 h-full">
                      <img src="/assets/images/icon/jbcodeapp_tech_flutter.svg" alt="Flutter" className="w-14 h-14 " />
                      <h5 className={`text-center pt-3 text-${textcolor}`}>Flutter</h5>
                    </div>
                  </a>
                  {/* <a className="showcase__item-4 mix brochure" href="/services/web/code-development-service"> */}
                  <div className="brand__item">
                    <i className="fa-brands fa-react fa-2x" style={{ fontSize: '3.5em', color: '#61DAFB', padding: '8px' }} />
                    <h5 className={`text-center text-${textcolor}`}>React Native</h5>
                  </div>
                  {/* </a> */}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Showcase;
